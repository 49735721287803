import styled from 'styled-components';

export const Input = styled.input`
    width: 430px;
    font-family: ${p => p.theme.fonts.main};
    font-size: 23px;
    border: none;
    border-bottom: 0.5px solid ${p => p.theme.colors.grey};
    outline: none;
    &::placeholder {
        color: #c1c0c0;
    }
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 19px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 180px;
        padding: 4px 0px;
        font-size: 14px;
    }
`;
