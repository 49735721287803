//const nameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const phoneRegex = /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/;

const validate = (form = {}) => {
    const errors = {};

    //if (!nameRegex.test(form.fullName)) errors['Full Name'] = 'Please enter a valid Full Name';
    if (!emailRegex.test(form.email)) errors['Email Address'] = 'Please enter a valid email';
    //if (!phoneRegex.test(form.phone)) errors['Phone Number'] = 'Please enter a valid phone';

    return { isValid: !Object.keys(errors).length, errors };
};

export default validate;
