import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import createDecorator from 'final-form-focus';
import Button from '../../components/Button';
import { InputAdapter } from '../../components/Input';
import { CheckboxAdapter } from '../../components/Checkbox';
import StringParser from '../../components/StringParser';
import { validate } from './helper';
import api from '../../services/api';
import MetaTags from 'react-meta-tags';

const focusOnError = createDecorator();

const SignUpPage = props => {
    const {
        match: { params }
    } = props;
    const [registrationData, setRegistrationData] = useState(false);
    const [initialValues, setInitialValues] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        tos: false
    });
    const [success, setSuccess] = useState(false);
    const { registration_email, token: regToken, email: regMail } = registrationData;
    const useRegistrationEmail = registration_email !== regMail;

    const termsLink = (
        <Link to="/terms-and-conditions" className="text-link-red">
            Terms and Conditions
        </Link>
    );

    const policyLink = (
        <Link to="/privacy-policy" className="text-link-red">
            Privacy Policy
        </Link>
    );

    const submitForm = async data => {
        const formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        formData.append('token', regToken);

        try {
            const res = await api.post('/users', formData);
            const { signup_errors } = res.data;
            if (signup_errors && signup_errors !== '0') throw Error(res.data.signup_errors);
            setSuccess(true);
        } catch (err) {
            console.log(err);
            return { [FORM_ERROR]: err.message };
        }
    };

    useEffect(() => {
        const getRegistrationData = async token => {
            const formData = new FormData();
            formData.append('token', token);
            try {
                const res = await axios.post(
                    `https://www.xchangeplus.com/customer_api/registration_token_validation`,
                    formData
                );
                if (!res.data.success !== 1) {
                    console.log(res);
                    setInitialValues({ ...initialValues, email: res.data.email });
                    setRegistrationData({ email: res.data.email, token: res.data.data });
                } else {
                    setInitialValues({ ...initialValues, email: 'Invalid token' });
                    throw Error('No invitee details found');
                }
            } catch (err) {
                console.log(err);
                return { [FORM_ERROR]: 'Token is invalid' };
            }
        };

        if (params.token) {
            getRegistrationData(params.token);
        }
    }, [params.token, initialValues]);

    if (success) {
        return (
            <div className="auth-sign-up">
                <h1 className="title">Thank you!</h1>
                <p className="subtitle">
                    <span>
                        Thank you! Please check your email in order to confirm your email address.{' '}
                    </span>
                    <br />
                </p>
            </div>
        );
    }

    return (
        <Container>
            <MetaTags>
                <meta name="description" content="Secure client login for HT Law. Create an account. Forgot password or username?" />
                <meta name="keywords" content="real,estate,attorney,chicago,cheap,affordable,login,client,portal,law,legal,lawyer,closing,closings,fast," />
                <meta name="author" content="HTClosings.com" />
                <title>HT Law LLC – Client Login for Fast and Affordable Real Estate Legal Services</title>
            </MetaTags>
            <h1 className="title">
                <span className="text-blue">Create</span> your XchangePlus account. <br />
                Take control of your success.
            </h1>
            <p className="text">
                Have an account already?{' '}
                <Link to="/login" className="text-link-blue">
                    Login →
                </Link>
            </p>

            <Form
                initialValues={initialValues}
                decorators={[focusOnError]}
                validate={validate}
                onSubmit={submitForm}
                render={form => (
                    <form onSubmit={form.handleSubmit}>
                        {useRegistrationEmail && (
                            <p className="input-item-hint registration-email">
                                *The Email Address shown is the email address that JS Holdings
                                requested for you to join under. This email address may be different
                                from the email address from which you received the invite.
                            </p>
                        )}

                        <Field
                            disabled={true}
                            label="Email Address"
                            name="email"
                            type="email"
                            component={InputAdapter}
                        />

                        <div className="input-row">
                            <Field
                                label="Password"
                                name="password"
                                type="password"
                                component={InputAdapter}
                            />
                            <Field
                                label="Confirm Password"
                                name="passwordConfirm"
                                type="password"
                                component={InputAdapter}
                            />
                        </div>

                        <Field
                            label={
                                <div className="checkbox-description">
                                    I have reviewed and agree to the {termsLink} and {policyLink}.
                                </div>
                            }
                            name="tos"
                            component={CheckboxAdapter}
                        />

                        <StringParser className="text-error">{form.submitError}</StringParser>

                        <Button type="submit" disabled={form.hasValidationErrors}>
                            {form.hasValidationErrors
                                ? 'All fields are required'
                                : 'Create account'}
                        </Button>
                    </form>
                )}
            />
        </Container>
    );
};

export default withRouter(SignUpPage);
