import React from 'react';
import { Container, TextBlock, ImgBlock, Head, TextInnerBlock, ContactInfo, BgImg } from './styles';
import MetaTags from 'react-meta-tags';
import bg from './bg.svg';

const LocationPage = () => (
    <Container>
        <MetaTags>
            <meta name="description" content="HT Law is conveniently located throughout the Chicagoland area. Get directions. View map." />
            <meta name="keywords" content="real,estate,attorney,chicago,cheap,affordable,locations,location,map,directions,address,office,offices,law,legal,lawyer,closing,closings,fast," />
            <meta name="author" content="HTClosings.com" />
            <title>HT Law LLC – Fast and Affordable Real Estate Legal Services Office Locations</title>
        </MetaTags>
        <TextBlock>
            <TextInnerBlock>
                <Head>Visit us at</Head>
                <ContactInfo>
                    HT LAW LLC
                    <br />
                    444 N Wabash Ave., Suite 220
                    <br />
                    Chicago, IL 60611
                    <br />
                    Phone: (312) 957-8991
                    <br />
                    Fax: (312) 858-3933
                    <br />
                    info@htclosings.com
                    <br />
                </ContactInfo>
            </TextInnerBlock>
        </TextBlock>
        <ImgBlock>
            <BgImg src={bg} alt="" />
        </ImgBlock>
    </Container>
);

export default LocationPage;
