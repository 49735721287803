import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const Button = styled(RouterNavLink)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100px;
    text-align: center;
    font-size: 20px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    color: #fff;
    background-color: #00edd0;
    border-radius: 3px;
    box-shadow: 9px 20px 40px ${props => props.theme.colors.primaryLight};
    border: none;
    cursor: pointer;
    transition: opacity 0.4s;
    text-decoration: none;
    &:hover {
        opacity: 0.8;
    }
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 340px;
        height: 79px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 240px;
        height: 55px;
        box-shadow: 9px 8px 20px ${props => props.theme.colors.primaryLight};
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 185px;
        height: 43px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        box-shadow: none;
    }
`;
