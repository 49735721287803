import React from 'react';
import { SectionHead } from './../../../../ui/SectionHead';
import {
    HowItWorksSectionStyled,
    Steps,
    Step,
    Leptop,
    Rocket,
    Wagon,
    Number,
    StepTitle
} from './styles';
import laptop from './laptop.svg';
import rocket from './rocket.svg';
import wagon from './wagon.svg';

const HowItWorksSection = () => (
    <HowItWorksSectionStyled>
        <SectionHead>How It Works</SectionHead>
        <Steps>
            <Step>
                <Leptop src={laptop} alt="" />
                <Number>1</Number>
                <StepTitle>Get in touch with us</StepTitle>
            </Step>
            <Step>
                <Rocket src={rocket} alt="" />
                <Number className="num-2">2</Number>
                <StepTitle>Streamline your closing</StepTitle>
            </Step>
            <Step>
                <Wagon src={wagon} alt="" />
                <Number>3</Number>
                <StepTitle>Move in/out!</StepTitle>
            </Step>
        </Steps>
    </HowItWorksSectionStyled>
);

export default HowItWorksSection;
