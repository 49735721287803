import styled from 'styled-components';

export const BtnPlusLine = styled.div`
    width: 11px;
    height: 1px;
    background-color: ${p => p.theme.colors.primary};
`;
export const BtnPlus = styled.div`
    width: 12px;
    height: 12px;
    position: relative;
    transition: transform 0.4s;
    transform: rotateZ(${p => (p.open ? '45deg' : '0')});
    cursor: pointer;
    ${BtnPlusLine} {
        &:nth-child(1) {
            position: absolute;
            top: 50%;
            left: 0%;
            margin-top: -1px;
            transform: rotateZ(90deg);
        }
        &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1px;
        }
    }
`;
export const AccordionItemHead = styled.div`
    color: ${p => p.theme.colors.grey};
    font-family: ${p => p.theme.fonts.HelveticaNeueRegular};
    font-size: 18px;
    cursor: pointer;
`;
export const AccordionItemBody = styled.div`
    display: ${p => (p.open ? 'block' : 'none')};
    color: ${p => p.theme.colors.dark};
    font-family: ${p => p.theme.fonts.main};
`;
export const AccordionItem = styled.div`
    position: relative;
    padding: 25px 0px;
    padding-left: 30px;
    border-bottom: 1px solid #cdd4d8;
    ${BtnPlus} {
        position: absolute;
        top: 30px;
        left: 5px;
        @media (max-width: ${props => props.theme.media.tablet}px) {
            top: 17px;
        }
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 15px 24px 15px 25px;
    }
`;
export const AccordionStyled = styled.div`
    ${AccordionItemBody} {
        margin-top: 25px;
        font-size: 16px;
        @media (max-width: ${props => props.theme.media.tablet}px) {
            margin-top: 15px;
        }
    }
`;
