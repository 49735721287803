import React, { useState, useEffect } from 'react';
import {
    HeaderStyled,
    LogoLink,
    Nav,
    BtnClose,
    BtnCloseLine,
    NavLink,
    BtnMenu,
    BtnMenuLine,
    LogoPicture,
    BgMenu
} from './styles.js';


// import { Button } from '../../ui/Button';
import LoginButton from './LoginButton';
import ReactPixel from 'react-facebook-pixel';


const options = {
    autoConfig: true, 
    debug: false, 
  };

ReactPixel.init('209456384042916', options);
ReactPixel.pageView(); // For tracking each page view 

const Header = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [borderMenu, setBorderMenu] = useState(false);

    const listener = e => {
        if (window.pageYOffset > 40 || document.documentElement.scrollTop > 40) {
            setBorderMenu(true);
        } else {
            setBorderMenu(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listener);
        return () => {
            window.removeEventListener('scroll', listener);
        };
    }, []);

    return (
        <HeaderStyled
            style={borderMenu ? { borderBottom: '1px solid #CDD4D8' } : { borderBottom: 'none' }}>
            <BgMenu active={isOpenMenu} onClick={() => setIsOpenMenu(false)} />
            <BtnMenu onClick={() => setIsOpenMenu(true)}>
                <BtnMenuLine />
                <BtnMenuLine />
                <BtnMenuLine />
            </BtnMenu>
            <LogoLink to="/" activeClassName="active" exact>
                <LogoPicture />
            </LogoLink>
            <Nav active={isOpenMenu}>
                <BtnClose onClick={() => setIsOpenMenu(false)}>
                    <BtnCloseLine />
                    <BtnCloseLine />
                </BtnClose>
                <NavLink
                    to="/"
                    className=" showMobile"
                    activeClassName="active"
                    exact
                    onClick={() => setIsOpenMenu(false)}>
                    Home
                </NavLink>
                <NavLink
                    to="/services"
                    activeClassName="active"
                    onClick={() => setIsOpenMenu(false)}>
                    Services
                </NavLink>
                <NavLink
                    to="/about-us"
                    activeClassName="active"
                    onClick={() => setIsOpenMenu(false)}>
                    About Us
                </NavLink>
                <NavLink
                    to="/location"
                    activeClassName="active"
                    onClick={() => setIsOpenMenu(false)}>
                    Location
                </NavLink>
                <NavLink
                    to="/contact"
                    activeClassName="active"
                    onClick={() => setIsOpenMenu(false)}>
                    Contact
                </NavLink>
                <NavLink to="/faq" activeClassName="active" onClick={() => setIsOpenMenu(false)}>
                    FAQ
                </NavLink>
                <LoginButton />
            </Nav>
        </HeaderStyled>
    );
};

export default Header;
