import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: auto;
`;

export const Content = styled.div`
    box-sizing: border-box;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 298px);
    min-height: 100vh;
    padding: 4rem 5rem;
    margin-left: 298px;

    .auth-sign-in,
    .auth-sign-up {
        .title {
            margin-bottom: 30px;
        }

        .text-error {
            p {
                margin-top: 0;
            }
        }
    }

    @media (max-width: 1439px) {
        width: calc(100% - 312px);
        margin-left: 312px;
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin-left: 0;
        align-items: flex-start;
        min-height: auto;
    }

    @media (max-width: 767px) {
        padding: 20px 10px 10px 10px;
    }
    overflow: auto;
`;
