import styled from 'styled-components';

export const Container = styled.div`
    max-width: 900px;
    font-family: InterUI;

    .title {
        white-space: nowrap;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;

        .text-blue {
            color: #349fff;
        }

        @media (max-width: 1023px) {
            white-space: unset;
        }
    }
    .text-link-blue {
        color: #349fff;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;

        .checkbox-description {
            font-size: 16px;
        }

        .text {
            font-size: 20px;
        }

        .text-link-red {
            color: #349fff;
            font-weight: 600;
            text-decoration: none;
            white-space: nowrap;
        }

        .input-row {
            display: flex;

            .input,
            .select {
                width: 50%;

                &:nth-of-type(2) {
                    margin-left: 2rem;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;

                .input,
                .select {
                    width: auto;

                    &:nth-of-type(2) {
                        margin-left: 0;
                    }
                }
            }
        }

        .checkbox {
            width: 100%;
        }

        .text-error {
            margin-top: 5rem;

            &:empty {
                display: none;
            }
        }

        .button {
            margin-top: 3rem;
        }

        .registration-email {
            margin-bottom: 3rem;
        }
    }

    &-success {
        font-size: 18px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 345px;

        .title {
            font-size: 41px;
            line-height: 60px;
        }

        .subtitle {
            color: #8b8b8b;
            font-size: 18px;
            line-height: 32px;

            a {
                color: $black;
                text-decoration: none;
            }
        }
    }

    @media (max-width: 1023px) {
        font-size: 16px;
        max-width: 100%;
    }
    @media (max-width: 767px) {
        .title {
            font-size: 20px;
            line-height: 36px;
        }
        .text {
            font-size: 14px;
        }
        form {
            .checkbox-description {
                font-size: 14px;
            }
            margin-top: 3rem;
            .button {
                width: 100%;
                white-space: nowrap;
            }
        }
    }
`;
