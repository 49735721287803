import styled from 'styled-components';

export const SectionHead = styled.h1`
    font-size: 70px;
    line-height: 1.2;
    color: ${props => props.theme.colors.dark};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
    font-size: 40px;

    /* @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 59px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 37px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        font-size: 29px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 22px;
    } */
`;
