import React from 'react';
import {BrowserRouter} from "react-router-dom";
import MainRoutes from './main';
import AuthRoutes from './auth';


const supportsHistory = 'pushState' in window.history;

const Router = () => (
	<BrowserRouter forceRefrech={!supportsHistory}>
		<AuthRoutes />
		<MainRoutes />
	</BrowserRouter>
);

export default Router;