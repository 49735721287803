import styled from 'styled-components';

export const ProvideSectionStyled = styled.section`
    padding-bottom: 490px;
    position: relative;
    overflow: hidden;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-bottom: 31%;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-bottom: 0;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-bottom: 0px;
    }
`;
export const SectionHead = styled.h2`
    margin-bottom: 150px;
    padding: 35px 0;
    color: #fcfcfc;
    background-color: #00edd0;
    font-family: ${props => props.theme.fonts.main};
    font-weight: 400;
    font-size: 42px;
    text-align: center;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 33px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 40px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 10px 40px;
        margin-bottom: 0px;
        font-size: 20px;
    }
`;
export const WrapText = styled.div`
    max-width: 1420px;
    margin: 0 auto;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        max-width: 1170px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        max-width: 820px;
        margin-bottom: 70px;
        box-sizing: border-box;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: initial;
        padding: 40px;
    }
`;
export const BgImg = styled.img`
    position: absolute;
    left: -110px;
    bottom: -95px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 120%;
        left: -10%;
        bottom: 0;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
`;
