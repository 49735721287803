import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { Container, Title } from './styles';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import isEmail from 'validator/lib/isEmail';
import { InputAdapter } from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import StringParser from '../../components/StringParser';
import MetaTags from 'react-meta-tags';
import api from '../../services/api';

const focusOnError = createDecorator();

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!isEmail(values.email)) {
        errors.email = 'Email is invalid';
    }
    if (!values.password) errors.password = 'Required';

    return errors;
};

const SignIn = ({ history }) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [rememberMeEmail, setRememberMeEmail] = useState('');
    const [rememberMePassword, setRememberMePassword] = useState('');

    useEffect(() => {
        const email = localStorage.getItem('rememberMeEmail');
        const password = localStorage.getItem('rememberMePassword');

        if (email && password) {
            setRememberMe(true);
            setRememberMeEmail(email);
            setRememberMePassword(password);
        }
    }, []);

    const forgotPasswordLink = (
        <Link to="/forgot_password" className="forgot-pass">
            Forgot Password?
        </Link>
    );

    const submitForm = async data => {
        if (rememberMe) {
            localStorage.setItem('rememberMeEmail', data.email);
            localStorage.setItem('rememberMePassword', data.password);
        } else {
            localStorage.removeItem('rememberMeEmail');
            localStorage.removeItem('rememberMePassword');
        }

        const formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        try {
            const res = await api.post('/tokens/issue', formData);
            const { login_errors } = res.data;

            if (login_errors && login_errors !== '0') {
                throw Error(login_errors);
            }

            console.log(res);
            //TODO ADD REDIRECT
            // window.location.replace(res.data.redirect);
        } catch (err) {
            return { [FORM_ERROR]: err.message };
        }
    };

    const handleCheckboxChange = ({ target: { checked } = {} }) => {
        setRememberMe(checked);
    };

    return (
        <Container>
            <MetaTags>
                <meta
                    name="description"
                    content="Secure client login for HT Closings. Create an account. Forgot password or username?"
                />
                <meta
                    name="keywords"
                    content="real,estate,attorney,chicago,cheap,affordable,login,client,portal,law,legal,lawyer,closing,closings,fast,"
                />
                <meta name="author" content="HTClosings.com" />
                <title>
                    HT Closings – Client Login for Fast and Affordable Real Estate Legal Services
                </title>
            </MetaTags>

            <Title>Welcome Back!</Title>

            <Form
                initialValues={{ email: rememberMeEmail, password: rememberMePassword }}
                decorators={[focusOnError]}
                validate={validate}
                onSubmit={submitForm}
                render={form => (
                    <form onSubmit={form.handleSubmit}>
                        <Field label="Email Address" name="email" component={InputAdapter} />
                        <Field
                            label="Password"
                            name="password"
                            type="password"
                            hint={forgotPasswordLink}
                            component={InputAdapter}
                        />
                        <Checkbox
                            label="Remember me"
                            checked={rememberMe}
                            onChange={handleCheckboxChange}
                        />
                        <StringParser className="text-error">{form.submitError}</StringParser>
                        <Button type="submit" disabled={form.submitting}>
                            Login
                        </Button>
                    </form>
                )}
            />
        </Container>
    );
};

export default SignIn;
