import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Router from 'containers/Router';

const AppContainer = styled.div`
    width: 100%;
    max-width: ${props => props.theme.media.laptopL}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        min-width: 320px;
    }
`;

const App = ({ theme }) => (
    <ThemeProvider theme={theme}>
        <AppContainer>
            <Router />
        </AppContainer>
    </ThemeProvider>
);

export default App;
