import styled from 'styled-components';

export const WhyUsSectionStyled = styled.section`
    position: relative;
    margin-top: 120px;
    padding: 40px;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-top: 70px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-top: 0px;
    }
`;

export const Container = styled.div`
    width: 1400px;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 1170px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: auto;
        max-width: 820px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: initial;
    }
`;

export const AirshipImg = styled.img`
    position: absolute;
    right: -170px;
    top: -300px;
    width: 400px;

    @media (max-width: ${props => props.theme.media.fullHD}px) {
        right: -110px;
        top: -240px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
`;

export const BgTopImg = styled.img`
    display: none;

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 400%;
        display: block;
        position: absolute;
        top: 0;
        left: -10%;
        z-index: -1;
    }
`;

export const BgRocketImg = styled.img`
    display: none;

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: block;
        width: 490px;
        position: absolute;
        top: -180px;
        right: -190px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 400px;
        top: -154px;
        right: -170px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 230px;
        top: -55px;
        right: -100px;
    }
`;

export const Title = styled.h3`
    font-family: ${props => props.theme.fonts.main};
    color: #00cfb2;
`;

export const Text = styled.p`
    margin-bottom: 30px;
    font-size: 20px;
    font-family: ${props => props.theme.fonts.main};
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: 400;

    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 20px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 15px;
    }
`;
