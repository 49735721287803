import React from 'react';
import IntroducingSection from './sections/IntroducingSection';
import BuyingSellingSection from './sections/BuyingSellingSection';
import PriceSection from './sections/PriceSection';
import HowItWorksSection from './sections/HowItWorksSection';
import ContactSection from './sections/ContactSection';
import { HomePageStyled } from './styles';
import MetaTags from 'react-meta-tags';

class HomePage extends React.Component {
    render() {
        return (
            <HomePageStyled>
                <MetaTags>
                    <meta name="description" content="HT Law premier real estate attorney services for the Chicagoland area. Introducing fast and easy high-tech closings for an unbeatable $299 flat fee!" />
                    <meta name="keywords" content="real,estate,attorney,chicago,cheap,affordable,law,legal,lawyer,closing,closings,fast," />
                    <meta name="author" content="HTClosings.com" />
                    <title>HT Law LLC - Fast and Affordable Real Estate Attorneys</title>
                </MetaTags>
                <IntroducingSection />
                <BuyingSellingSection />
                <PriceSection />
                <HowItWorksSection />
                <ContactSection />
            </HomePageStyled>
        );
    }
}

export default HomePage;
