import styled from 'styled-components';

export const ContactStyled = styled.section`
    position: relative;
    padding-top: 100px;
    padding-bottom: 540px;
    text-align: center;
    overflow: hidden;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-bottom: 200px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-top: 75px;
        padding-bottom: 220px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding-top: 50px;
        padding-bottom: 150px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding-top: 20px;
        padding-bottom: 135px;
    }
`;
export const SectionHead = styled.h1`
    margin-bottom: 70px;
    font-size: 70px;
    line-height: 1.2;
    color: ${props => props.theme.colors.dark};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
    text-align: center;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 51px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin-bottom: 50px;
        font-size: 32px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 15px;
        font-size: 22px;
    }
`;
export const BgImg = styled.img`
    position: absolute;
    right: -245px;
    bottom: -160px;
    z-index: -1;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 1050px;
        right: -10px;
        bottom: -130px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 1000px;
        right: -10px;
        bottom: -75px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 700px;
        right: -10px;
        bottom: -55px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 400px;
        right: -10px;
        bottom: -30px;
    }
`;
