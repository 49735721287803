import React from 'react';
import {
    IntroducingSectionStyled,
    Title,
    SectionHead,
    SectionSubHead,
    BgImg,
    BgMobile
} from './styles';
import bg from './bg.svg';

const IntroducingSection = () => (
    <IntroducingSectionStyled>
        <SectionHead>Introducing High Tech Closings</SectionHead>
        <SectionSubHead>Lightning fast. Transparent. Hassle-free.</SectionSubHead>
        <Title>(yes, we are talking about real estate)</Title>
        <BgImg src={bg} alt="" />
        <BgMobile src={bg} alt="" />
    </IntroducingSectionStyled>
);

export default IntroducingSection;
