import React from 'react';
import {
    InfoRolesSectionStyled,
    Price,
    RoleInfo,
    RoleInfoDescription,
    RoleInfoDescriptionHead,
    RoleInfoDescriptionText,
    RoleInfoServices,
    RoleInfoServicesHead,
    RoleInfoServicesItem
} from './styles';
import price from './price.png';

const servicesBuyers = [
    'Keeping clients informed at every step of the process and addressing any concerns that arise',
    'Drafting and reviewing contracts',
    'Document review',
    'Closing document preparation',
    'Scheduling the closing',
    'Title report review',
    'Correspondence with the seller’s attorney and other parties',
    'Attending and directing the final closing'
];
const servicesSellers = [
    'Keeping clients informed at every step of the process and addressing any concerns that arise',
    'Drafting and reviewing contracts',
    'Document review',
    'Closing document preparation',
    'Ordering and reviewing the title report',
    'Obtain all necessary zoning certificates, water department certificates, paid assessment letters, surveys, loan payoffs, and routine releases',
    'Submitting a payoff for outstanding mortgages',
    'Scheduling the closing',
    'Prepare state, county, and municipal transfer tax declarations',
    'Correspondence with the buyer’s attorney and other parties',
    'Attending and directing the final closing'
];
const InfoRolesSection = () => (
    <InfoRolesSectionStyled>
        <RoleInfo id="buyers">
            <Price src={price} alt="" />
            <RoleInfoDescription>
                <RoleInfoDescriptionHead>Buyers</RoleInfoDescriptionHead>
                <RoleInfoDescriptionText>
                    Real estate transactions are where we’re at. We’ll walk you through the process
                    and ensure that you get the best outcome for your unique needs and
                    circumstances. Whether you’re seasoned or just buying your first home, you
                    deserve the best legal counsel for your hard earned dollar. It’s a big deal and
                    we take each and every case seriously.
                </RoleInfoDescriptionText>
            </RoleInfoDescription>
            <RoleInfoServices>
                <RoleInfoServicesHead>Services for Buyers</RoleInfoServicesHead>
                {servicesBuyers.map((service, key) => (
                    <RoleInfoServicesItem key={key}>{service}</RoleInfoServicesItem>
                ))}
            </RoleInfoServices>
        </RoleInfo>
        <RoleInfo id="sellers">
            <Price src={price} alt="" />
            <RoleInfoDescription>
                <RoleInfoDescriptionHead>Sellers</RoleInfoDescriptionHead>
                <RoleInfoDescriptionText>
                    Real estate transactions are where we’re at. We’ll walk you through the process
                    and ensure that you get the best outcome for your unique needs and
                    circumstances. Whether you’re seasoned or just buying your first home, you
                    deserve the best legal counsel for your hard earned dollar. It’s a big deal and
                    we take each and every case seriously.
                </RoleInfoDescriptionText>
            </RoleInfoDescription>
            <RoleInfoServices>
                <RoleInfoServicesHead>Services for Sellers</RoleInfoServicesHead>
                {servicesSellers.map((service, key) => (
                    <RoleInfoServicesItem key={key}>{service}</RoleInfoServicesItem>
                ))}
            </RoleInfoServices>
        </RoleInfo>
    </InfoRolesSectionStyled>
);

export default InfoRolesSection;
