import React from 'react';
import { PageStyled, Title, Container, Label, Text } from './styles';

const PolicyPage = () => (
    <PageStyled>
        <Title>Privacy Policy</Title>
        <Container>
            <Text>
                HT Law LLC is committed to respecting your data privacy. We
                provide this Privacy Policy to inform users about our policies and procedures
                regarding the collection, use, security, and disclosure of personal data received
                from users of our website found at www.htclosings.com
                <br />
                You accept and agree to the terms in this policy when you use our website.
            </Text>
        </Container>
        <Container>
            <Label>The information we collect.</Label>
            <Text>
                The only personal information that we collect is what you provide voluntarily to us.
                This includes contact information such as name, email, and phone number, as well as
                whatever information you provide us through correspondence.
            </Text>
        </Container>
        <Container>
            <Label>How we collect information.</Label>
            <Text>We collect information directly from you.</Text>
        </Container>
        <Container>
            <Label>How we use the information we collect.</Label>
            <Text>
                To respond to your questions and requests.
                <br />
                To communicate or correspond with you.
                <br />
                For other business purposes.
                <br />
                This includes sharing information with our business partners, if we provide joint
                services.
            </Text>
        </Container>
        <Container>
            <Label>Our commitment to data security.</Label>
            <Text>
                This firm uses industry standard means to safeguard and secure personal information
                collected online. Although we do our best to protect your data, we cannot guarantee
                the security of information transmitted to our website via the Internet; any
                transmission is sent at your own risk.
            </Text>
        </Container>
        <Container>
            <Label>Policy updates.</Label>
            <Text>
                We may update our Privacy Policy periodically. The latest version will be posted to
                this page.
            </Text>
        </Container>
        <Container>
            <Label>
                If you have any questions or concerns about our Privacy Policy, please contact us.
            </Label>
        </Container>
    </PageStyled>
);

export default PolicyPage;
