import { useEffect, useCallback } from 'react';

const defaultConfig = { condition: true };

const useOutsideClick = (target, callback, { condition } = defaultConfig) => {
  const handleClick = useCallback(
    (e) => {
      if (target && target.contains && !target.contains(e.target)) callback(e);
    },
    [callback, target]
  );

  useEffect(() => {
    if (!condition) return;

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [condition, handleClick]);
};

export default useOutsideClick;
