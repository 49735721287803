import React, { memo } from 'react';
import './Checkbox.css';
import classNames from 'classnames';

const Checkbox = props => {
    const { className, checked, error, label, disabled, ...rest } = props;

    return (
        <div className={classNames('checkbox', className, { disabled })}>
            <input type="checkbox" checked={checked} className="checkbox-control" {...rest} />
            <span className="checkbox-icon">
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M13.6666 0.874666L12.4107 0.116452C12.0632 -0.0925434 11.5872 -0.0123472 11.3546 0.293854L5.1985 8.35717L2.36942 5.84437C2.07393 5.58191 1.59238 5.58191 1.29689 5.84437L0.22162 6.79943C-0.0738733 7.06189 -0.0738733 7.4896 0.22162 7.75449L4.57194 11.6185C4.81545 11.8347 5.1985 12 5.54324 12C5.88798 12 6.23546 11.808 6.45981 11.5188L13.869 1.81028C14.1043 1.50408 14.014 1.08366 13.6666 0.874666Z" />
                    </g>
                </svg>
            </span>
            <label className="checkbox-label">{label}</label>
        </div>
    );
};

export const CheckboxAdapter = props => {
    const { label, meta, input } = props;
    const handleChange = e => {
        const { checked } = e.target;
        input.onChange(checked);
    };

    return (
        <Checkbox
            label={label}
            checked={input.value}
            error={meta.touched && meta.error}
            onChange={handleChange}
        />
    );
};

export default memo(Checkbox);
