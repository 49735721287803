import React from 'react';
import { WhyUsSectionStyled, Title, Text, Container } from './styles';
const WhyUsSection = () => (
    <WhyUsSectionStyled>
        <Container>
            <Title>Why Us?</Title>
            <Text>
                No other firm can get you from contract to closing, faster. How do we manage it?
                Partly because we use the most up-to-date data interchange technology programs to
                transact our work.
            </Text>
            <Text>
                The bottom line is that everybody benefits from a tech upgrade, but unfortunately
                most law firms are stuck in their last century ways (you won’t find overflowing file
                cabinets or rolodexes in our office)—they just haven’t changed since the day they
                opened their practices.
            </Text>
            <Text>
                Adopting to the new means that we are free to spend our time on what really
                matters—practicing law (aka helping you out). We take pride in our ability to
                streamline the best transaction experience for all of our clients.
            </Text>
        </Container>
    </WhyUsSectionStyled>
);

export default WhyUsSection;
