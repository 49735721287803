import React from 'react';

import Icon from 'components/Icon';
import { SubmitPopupRoot, Shadow, TransitionGroup } from './styles';
import { ReactComponent as CheckSVG } from './checking-mark-circle.svg';
import { ReactComponent as ErrorSVG } from './error-circle.svg';

const SubmitPopup = ({ show = true, title, text, error, onDismiss }) => {
    return (
        <TransitionGroup
            transitionName="transition"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
            {show && (
                <Shadow onClick={onDismiss}>
                    <SubmitPopupRoot>
                        <button onClick={onDismiss} className="cancel-btn">
                            <Icon name="cancel" fill="#3ee4cd" width="15" height="15" />
                        </button>

                        <div className="check-icon-container">
                            {error ? <ErrorSVG /> : <CheckSVG />}
                        </div>
                        <h1 className="title">{title}</h1>
                        <p className="text">{text}</p>
                    </SubmitPopupRoot>
                </Shadow>
            )}
        </TransitionGroup>
    );
};

export default SubmitPopup;
