import React from 'react';
import { Button } from './../../../../ui/Button';
import { ContactStyled, BgImg, SectionHead } from './styles';
import bg from './bg.svg';

const Contact = () => (
    <ContactStyled>
        <SectionHead fontSize="54px">Have a Question?</SectionHead>
        <Button to="/contact">Contact Us</Button>
        <BgImg src={bg} alt="" />
    </ContactStyled>
);

export default Contact;
