import React from 'react';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import { FORM_ERROR } from 'final-form';
import { InputAdapter } from '../../components/Input';
import createDecorator from 'final-form-focus';
import StringParser from '../../components/StringParser';
import api from '../../services/api';
import { Container, Title } from './styles';
import { validate } from './helper';
import queryString from 'query-string';

const focusOnError = createDecorator();

const ResetPassword = ({ location: { search } }) => {
    const values = queryString.parse(search);
    const token = values.token;
    const email = values.email;

    const submitForm = values => {
        const formData = new FormData();
        formData.append('password', values.password);
        formData.append('token', token);
        formData.append('email', email);

        api.post(`/password/reset`, formData)
            .then(response => {
                const { data: { login_errors } = {} } = response;
                if (login_errors && login_errors !== '0') {
                    return Promise.reject(login_errors);
                }
                return response;
            })
            .then(res => {
                console.log('success', res);
            })
            .catch(err => {
                console.log(err);
                return { [FORM_ERROR]: err.message };
            });
    };

    return (
        <Container>
            <Title>Reset password</Title>
            <Form
                decorators={[focusOnError]}
                validate={validate}
                onSubmit={submitForm}
                render={form => (
                    <form onSubmit={form.handleSubmit}>
                        <Field
                            label="Password"
                            name="password"
                            type="password"
                            component={InputAdapter}
                        />
                        <Field
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            component={InputAdapter}
                        />
                        <StringParser className="text-error">{form.submitError}</StringParser>
                        <Button type="submit" disabled={form.submitting} style={{ marginTop: 40 }}>
                            Change password
                        </Button>
                    </form>
                )}
            />
        </Container>
    );
};

export default withRouter(ResetPassword);
