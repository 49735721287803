import React from 'react';
import {
    FooterStyled,
    FooterLogoLink,
    FooterNav,
    FooterNavItem,
    FooterCopyRight,
    FooterTop,
    FooterBottom,
    FooterLinks,
    FooterLinksItem,
    LogoStyles
} from './styles';
import FooterNavMobile from './FooterNavMobile';

const navigationData = {
    contact: [{ name: 'Contact HT Law', link: '/contact' }],
    about: [{ name: 'Services', link: '/services' }, { name: 'About us', link: '/about-us' }],
    resources: [{ name: 'FAQ', link: '/faq' }]
};

const Footer = () => (
    <FooterStyled>
        <FooterTop>
            <FooterLogoLink to="/">
                <LogoStyles />
            </FooterLogoLink>
            <FooterNav>
                <FooterNavItem to="/services">Services</FooterNavItem>
                <FooterNavItem to="/about-us">About us</FooterNavItem>
                <FooterNavItem to="/contact">Contact</FooterNavItem>
                <FooterNavItem to="/faq">FAQ</FooterNavItem>
            </FooterNav>
            <FooterNavMobile navigationData={navigationData} />
        </FooterTop>
        <FooterBottom>
            <FooterCopyRight>© HT Law LLC. All rights reserved.</FooterCopyRight>
            <FooterLinks>
                <FooterLinksItem to="/attorney">Attorney Advertising</FooterLinksItem>
                <FooterLinksItem to="/policy">Privacy Policy</FooterLinksItem>
                <FooterLinksItem to="/legal">Legal Disclaimer</FooterLinksItem>
            </FooterLinks>
        </FooterBottom>
    </FooterStyled>
);
export default Footer;
