import styled from 'styled-components';

export const Container = styled.div`
    width: 600px;
    font-family: InterUI;

    .forgot-pass {
        color: #8b8b8b;
        text-decoration: none;
    }

    form {
        margin-top: 50px;

        .error-text {
            color: #349fff;
            font-size: 12px;
            margin-top: -2px;
        }

        .forgot-pass {
            color: #8b8b8b;
            text-decoration: none;
        }

        .input {
            &:nth-of-type(2) {
                margin-bottom: 20px;
            }
        }

        .checkbox {
            display: flex;
            margin: 3rem 0;
        }

        .button {
            width: 100%;
        }
    }

    @media (min-width: 1659px) {
        width: 900px;
        form {
            .button {
                width: 360px;
            }
        }
        .forgot-pass {
            font-size: 18px;
            position: absolute;
            right: -180px;
            top: 15px;
        }
    }
    @media (max-width: 1023px) {
        font-size: 16px;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    color: #349fff;
    font-size: 40px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
    }
`;
