import styled from 'styled-components';

export const HowItWorksSectionStyled = styled.section`
    overflow: hidden;
    padding: 145px 0;
    background-color: #fdfdfd;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding: 110px 0;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding: 45px 0;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        padding: 35px 0;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 25px 0;
    }
`;

export const Steps = styled.div`
    display: flex;
    margin-top: 105px;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        max-width: 1024px;
        padding: 0px 55px;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        max-width: 760px;
        margin-top: 50px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 320px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        padding: 0;
    }
`;

export const Number = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 50%;
    background-color: #b4c6cb;
    color: #fff;
    font-size: 31px;
    font-family: 'stencil-std', sans-serif;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 50px;
        height: 50px;
        font-size: 25px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 33px;
        height: 33px;
        font-size: 15px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        font-size: 15px;
        &.num-2 {
            margin-top: 0px;
        }
    }
`;
export const StepTitle = styled.div`
    max-width: 110px;
    text-align: center;
    color: ${props => props.color || props.theme.colors.dark};
    font-size: 20px;
    font-family: ${props => props.theme.fonts.main}, sans-serif;
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &:nth-child(3) {
        ${Number} {
            margin-left: -580px;
            margin-top: -145px;
        }
        ${StepTitle} {
            margin-left: -580px;
        }
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        &:nth-child(3) {
            width: 100%;
            ${Number} {
                margin-left: -365px;
                margin-top: -90px;
            }
            ${StepTitle} {
                margin-left: -365px;
            }
        }
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        &:nth-child(3) {
            width: 100%;
            ${Number} {
                margin-left: -290px;
                margin-top: -65px;
            }
            ${StepTitle} {
                margin-left: -290px;
            }
        }
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 180px;
        &:nth-child(1) {
            width: 50%;
            margin-bottom: 30px;
        }
        &:nth-child(2) {
            width: 50%;
            margin-bottom: 30px;
        }
        &:nth-child(3) {
            width: 100%;
            ${Number} {
                margin-left: 0;
                margin-top: -35px;
            }
            ${StepTitle} {
                margin-left: 0;
            }
        }
    }
    ${Number} {
        &.num-2 {
            margin-top: -75px;
        }
        @media (max-width: ${props => props.theme.media.laptopL}px) {
            &.num-2 {
                margin-top: -40px;
            }
        }
    }
`;

export const Leptop = styled.img`
    position: relative;
    z-index: 3;
    width: 380px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 380px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 255px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 195px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 145px;
    }
`;
export const Rocket = styled.img`
    position: relative;
    left: -111px;
    width: 590px;
    top: 0;
    z-index: 2;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 590px;
        top: 0;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 390px;
        top: 5px;
        left: -65px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 300px;
        top: 0px;
        left: -50px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 231px;
        left: -38px;
        top: 15px;
        margin-bottom: 34px;
    }
`;
export const Wagon = styled.img`
    position: relative;
    top: -90px;
    left: -342px;
    width: 850px;
    z-index: 1;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 850px;
        top: -70px;
        left: -300px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 560px;
        top: -48px;
        left: -187px;
    }
    @media (max-width: ${props => props.theme.media.laptop}px) {
        width: 420px;
        top: -35px;
        left: -144px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 100%;
        top: 0;
        left: 0;
        margin-bottom: 15px;
    }
`;
