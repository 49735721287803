import React from 'react';
import {
    SelectRoleSectionStyled,
    Content,
    Head,
    SubHead,
    WrapBtns,
    BgImg,
    Button,
    BgMobileImg
} from './styles';
import bg from './bg.svg';
import bgMobile from './bg-mobile.svg';

const SelectRoleSection = () => (
    <SelectRoleSectionStyled>
        <Content>
            <Head>Fast-track your real estate transaction!</Head>
            <SubHead>Select your role below to see what services we can offer you.</SubHead>
            <WrapBtns>
                <Button to="#buyers">Buyer</Button>
                <Button to="#sellers">Seller</Button>
            </WrapBtns>
        </Content>
        <BgImg src={bg} alt="" />
        <BgMobileImg src={bgMobile} alt="" />
    </SelectRoleSectionStyled>
);

export default SelectRoleSection;
