import styled from 'styled-components';
import { colors } from 'styleguide/variables';

export const Label = styled.label`
    pointer-events: none;
    position: absolute;
    padding: 0 5px;
    line-height: 20px;
    left: 10px;
    top: 22px;
    transition: all 0.35s ease-out;

    &.raised {
        pointer-events: none;
        color: ${colors.grayDark};
        transform: translate(-10px, -30px) scale(0.8);
        background-color: ${colors.white};
    }
`;

export const Error = styled.div`
    box-sizing: border-box;
    color: ${colors.white};
    background-color: ${colors.pinkRed};
    width: 100%;
    height: 30px;
    padding: 0 15px;
    font-weight: 600;
    font-size: 12px;
    font-style: italic;
    line-height: 30px;

    &:empty {
        display: none;
    }
`;

export const Hint = styled.div`
    color: ${colors.grayDark};
    font-size: 12px;
    padding: 0 10px;
    margin-top: 5px;

    a {
        transition: all 0.35s ease-out;
        &:hover {
            color: ${colors.pinkRed};
        }
    }
`;
