import React from 'react';
import { AboutSectionStyled, Container, BgImg, Text, AirshipImg, TextWrapper } from './styles.js';
import bg from './bg.svg';
import airship from './airship.svg';

const AboutSection = () => (
    <AboutSectionStyled>
        <Container>
            <BgImg src={bg} alt="background" />
            <TextWrapper className="textWrapper">
                <Text>
                    We get it, real estate transactions are totally stressful, and on top of that
                    the search for legal counsel can be overwhelming. We want to make every step of
                    the process easier for you, so we’ll get right to it
                </Text>
            </TextWrapper>
        </Container>
        <AirshipImg src={airship} />
    </AboutSectionStyled>
);

export default AboutSection;
