import React from 'react';
import SideBar from '../components/SideBar';
import { Wrapper, Content } from './auth-styles';

const Auth = ({ children }) => {
    return (
        <Wrapper>
            <SideBar />
            <Content>{children}</Content>
        </Wrapper>
    );
};

export default Auth;
