import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export const SubmitPopupRoot = styled.div`
    height: 360px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 50px 26px 20px 26px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f7f7f7;
    border-radius: 7px;
    box-sizing: border-box;

    .cancel-btn {
        position: absolute;
        right: 15px;
        top: 20px;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
    }

    .check-icon-container {
        margin: 20px 0 20px 0;
    }

    .title {
        font: bold 20px ${props => props.theme.fonts.main};
        color: #707070;
    }

    .text {
        font: 20px ${props => props.theme.fonts.main};
        color: #707070;
    }
`;

export const Shadow = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #dfdfdfab;
    z-index: 1001;
`;

export const TransitionGroup = styled(ReactCSSTransitionGroup)`
    .transition-enter {
        opacity: 0.01;

        &.transition-enter-active {
            opacity: 1;
            transition: opacity 300ms ease-in;
        }
    }

    .transition-leave {
        opacity: 1;

        &.transition-leave-active {
            opacity: 0.01;
            transition: opacity 300ms ease-in;
        }
    }
`;
