import styled from 'styled-components';
import { FormControl, Button } from './../../ui';

export const ContactPageStyled = styled.div`
    padding-top: 120px;
    padding-bottom: 100px;
    overflow: hidden;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        padding-left: 60px;
        padding-right: 140px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        padding: 43px;
    }
    @media (max-width: 364px) {
        padding: 21px;
    }
`;
export const Container = styled.div`
    max-width: 1700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
`;
export const Content = styled.div`
    max-width: 605px;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        margin: 0 auto;
    }
`;
export const ContentTop = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const ContentTopAddress = styled.div``;
export const ContentTopImg = styled.div`
    @media (max-width: ${props => props.theme.media.tablet}px) {
        position: absolute;
        right: -10px;
    }
`;
export const BgImgMob = styled.img`
    display: none;
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        width: 400px;
        display: inline-block;
        position: relative;
        right: -30px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        width: 169px;
    }
    @media (max-width: 340px) {
        display: none;
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    margin: 25px 0px;
    background-color: #e4e4e4;
    @media (max-width: ${props => props.theme.media.tablet}px) {
        display: none;
    }
`;
export const BgImg = styled.img`
    position: relative;
    top: -50px;
    width: 800px;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        width: 800px;
        margin-bottom: -100px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        display: none;
    }
`;
export const Head = styled.h2`
    font-size: 41px;
    font-family: ${props => props.theme.fonts.main};
    font-weight: 400;
    margin-bottom: 27px;
    color: ${props => props.theme.colors.grey};
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 33px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 25px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        margin-bottom: 22px;
        font-size: 22px;
    }
`;
export const ContactInfo = styled.div`
    font-size: 23px;
    color: ${props => props.theme.colors.grey};
    font-family: ${props => props.theme.fonts.main};
    font-weight: 300;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        max-width: 155px;
        margin-bottom: 30px;
        font-size: 16px;
    }
`;
export const FormHead = styled.h3`
    margin-bottom: 50px;
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.main};
    font-size: 25px;
    font-weight: 400;
    @media (max-width: ${props => props.theme.media.fullHD}px) {
        margin-bottom: 40px;
        font-size: 20px;
    }
    @media (max-width: ${props => props.theme.media.laptopL}px) {
        font-size: 24px;
    }
    @media (max-width: ${props => props.theme.media.tablet}px) {
        font-size: 22px;
    }
`;
export const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // padding: 0 15px;
    ${FormControl} {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
    ${Button} {
        width: 100%;
        height: 65px;
        margin-top: 30px;
        font-size: 24px;
        @media (max-width: ${props => props.theme.media.fullHD}px) {
            height: 50px;
            margin-top: 30px;
            font-size: 20px;
        }
        @media (max-width: ${props => props.theme.media.tablet}px) {
            height: 30px;
            margin-top: 0;
            font-size: 12px;
        }
    }
`;

export const CounterCharacters = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    color: #b7b7b7;
    font-family: ${props => props.theme.fonts.main};
    font-size: 11px;
`;
export const CountCharacters = styled.div`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.main};
    font-size: 11px;
`;
export const MaxCharacters = styled.div`
    color: #b7b7b7;
    font-family: ${props => props.theme.fonts.main};
    font-size: 11px;
`;

export const SubmitButton = Button.withComponent('button');
