import useOutsideClick from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const LoginButton = () => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        window.addEventListener('xp-close', () => setOpen(false));
    }, []);

    useEffect(() => {
        if (open) window.xpAuthInit({ selector: '#xp-auth' });
    }, [open]);

    useOutsideClick(ref.current, () => {
        setOpen(false);
    });

    return (
        <StyledContainer ref={ref} className="login-button-container">
            <StyledButton onClick={() => setOpen(!open)}>Sign In ▾</StyledButton>
            {open && <div id="xp-auth" data-api="https://www.xchangeplus.com" />}
        </StyledContainer>
    );
};

export default LoginButton;

const StyledContainer = styled.div`
    display: inline-block;
    position: relative;

    #xp-auth {
        position: absolute;
        right: 0;
        margin-top: 18px;

        & > .button {
            width: auto;
            white-space: nowrap;
            font-size: 16px;
            line-height: 20px;

            svg {
                flex-shrink: 0;
            }

            &:hover {
                background: white;
            }
        }

        @media (max-width: ${props => props.theme.media.tablet}px) {
            left: 0;
        }
    }
`;

const StyledButton = styled.button`
    border: none;
    background: none;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;

    @media (max-width: ${props => props.theme.media.tablet}px) {
        color: white;
        font-weight: 300;
        font-size: 15px;
    }
`;
