import React from 'react';
import {
    AccordionStyled,
    AccordionItem,
    AccordionItemHead,
    AccordionItemBody,
    BtnPlus,
    BtnPlusLine
} from './styles';

class Accordion extends React.Component {
    state = {
        openedItems: []
    };
    toggleItem = index => event => {
        const { openedItems } = this.state;
        const currentIndex = openedItems.findIndex(item => item === index);

        if (currentIndex === -1) {
            this.setState({ openedItems: [index] });
        } else {
            openedItems.splice(currentIndex, 1);
            this.setState({ opensItems: [...openedItems] });
        }
    };
    render() {
        const { openedItems } = this.state;
        const { items } = this.props;
        const { toggleItem } = this;

        return (
            <AccordionStyled>
                {items.map((item, key) => {
                    const isOpen = openedItems.includes(key);

                    return (
                        <AccordionItem key={key}>
                            <BtnPlus open={isOpen} onClick={toggleItem(key)}>
                                <BtnPlusLine />
                                <BtnPlusLine />
                            </BtnPlus>
                            <AccordionItemHead onClick={toggleItem(key)}>
                                {item.head}
                            </AccordionItemHead>
                            <AccordionItemBody open={isOpen}>{item.body}</AccordionItemBody>
                        </AccordionItem>
                    );
                })}
            </AccordionStyled>
        );
    }
}

export default Accordion;
