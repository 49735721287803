import React from 'react';
import { AboutUsPageStyled } from './styles';
import AboutSection from './sections/AboutSection';
import WhyUsSection from './sections/WhyUsSection';
import ProvideSection from './sections/ProvideSection';
import MetaTags from 'react-meta-tags';

const AboutUsPage = () => (
    <AboutUsPageStyled>
        <MetaTags>
            <meta name="description" content="Real estate transactions are where we’re at! We care about our clients and their legal concerns. Click here to learn more about what we do." />
            <meta name="keywords" content="real,estate,attorney,chicago,cheap,affordable,about,law,legal,lawyer,closing,closings,fast," />
            <meta name="author" content="HTClosings.com" />
            <title>HT Law LLC – About Us Real Estate Attorneys</title>
        </MetaTags>
        <AboutSection />
        <WhyUsSection />
        <ProvideSection />
    </AboutUsPageStyled>
);

export default AboutUsPage;
