import React from 'react';
import MetaTags from 'react-meta-tags';
import {
    FaqPageStyled,
    Content,
    Head,
    Contact,
    Email,
    AccordionHead,
    BgImg,
    BgMobileImg
} from './styles';
import { Accordion } from './../../ui';
import bg from './bg.svg';
// import bgLaptop from './bg-laptop.svg';
import bgMobile from './bg-mobile.svg';

const accordionItemsFAQ = [
    {
        head: 'Why should I choose HT Law legal services?',
        body:
            'We provide the highest quality service for an affordable price. We believe that technology should be leveraged to continuously improve the legal process for everyone’s benefit. We love efficiency!'
    },
    {
        head: 'How much do your services cost?',
        body:
            'We charge a $299 flat fee per transaction whether you are buying or selling real estate.'
    },
    {
        head: 'When do I pay?',
        body:
            'Payment is due at closing, and we only charge for finished jobs. So, if you don’t close, you are not required to pay the fee.'
    },
    {
        head: 'How can you charge so much less than other real estate attorneys? What’s the catch?',
        body:
            'There is no catch! We are highly experienced professionals that have streamlined services by continuously updating our practice and implementing the latest technology. Our legal representation is the highest quality and real estate transactions are our specialized niche. We are driven to perfect the process on every end, saving everyone time, effort, and money.'
    },
    {
        head: 'Which localities do you serve?',
        body:
            'We are currently serving the Chicagoland area, including the following counties: Cook, DuPage, Kane, Kendall, Lake, McHenry, and Will.'
    },
    {
        head: 'How do I get a login ID and password?',
        body: 'New clients will be emailed a link by their attorney or broker to create an account.'
    }
];

const FaqPage = () => (
    <FaqPageStyled>
        <MetaTags>
            <meta
                name="description"
                content="Have questions about our legal practice or the realestate services we provide? Check out our Frequently Asked Questions"
            />
            <meta
                name="keywords"
                content="real,estate,attorney,chicago,cheap,affordable,FAQ,law,legal,lawyer,closing,closings,fast,"
            />
            <meta name="author" content="HTClosings.com" />
            <title>HT Law LLC – FAQ Fast and Affordable Real Estate Legal Services</title>
        </MetaTags>
        <BgImg src={bg} alt="" />
        <Content>
            <BgMobileImg src={bgMobile} alt="" />
            <Head>Have questions about the services we provide?</Head>
            <Contact>
                Contact us at anytime
                <Email>info@htclosings.com</Email>
            </Contact>
            <AccordionHead>Frequently Asked Questions</AccordionHead>
            <Accordion items={accordionItemsFAQ} />
        </Content>
    </FaqPageStyled>
);

export default FaqPage;
